<script>
  import svg from '../images/aid.svg?raw';
  import InlineSVG from "~/components/InlineSVG.svelte";

  export let theme = '';

</script>

<div class="{theme}">
  <InlineSVG {svg} />
</div>

<style>
    @import '../common/colors.css';
    div {
        width: 100%;
    }
    div:global(.header .aid-logo--a) {
        fill: var(--pink);
        stroke: var(--white);
        stroke-width: 0;
    }
    div:global(.header .aid-logo--id) {
        fill:var(--white);
        stroke: var(--white);
        stroke-width: 0;
    }
    div:global(.black .aid-logo--id) {
        fill: var(--black);
    }
</style>
